export const SET_STEPS_NUMBER = 'SET_STEPS_NUMBER';
export const GO_NEXT = 'GO_NEXT';
export const GO_BACK = 'GO_BACK';
export const GO_TO_STEP = 'GO_TO_STEP';

export const setSteps = (steps) => ({
  type: SET_STEPS_NUMBER,
  steps,
});

export const goNext = () => ({
  type: GO_NEXT,
});

export const goBack = () => ({
  type: GO_BACK,
});

export const goToStep = (stepNumber) => ({
  type: GO_TO_STEP,
  step: stepNumber,
});
