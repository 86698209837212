export const UPDATE_SHIPPING_INFO = 'UPDATE_SHIPPING_INFO';
export const UPDATE_BILLING_INFO = 'UPDATE_BILLING_INFO';
export const CHARGING = 'CHARGING';
export const CHARGED = 'CHARGED';
export const SHOULD_SHOW_CART = 'SHOULD_SHOW_CART';
export const SHOULD_HIDE_CART = 'SHOULD_HIDE_CART';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_CREDIT_ORDER_ID = 'SET_CREDIT_ORDER_ID';
export const GET_CREDIT_ORDER = 'GET_CREDIT_ORDER';

export const updateShippingInfo = (field, value) => ({
  type: UPDATE_SHIPPING_INFO,
  field,
  value,
});

export const updateBillingInfo = (field, value) => ({
  type: UPDATE_BILLING_INFO,
  field,
  value,
});

export const charging = () => ({
  type: CHARGING,
});

export const charged = () => ({
  type: CHARGED,
});

export const shouldShowCart = () => ({
  type: SHOULD_SHOW_CART,
});

export const shouldHideCart = () => ({
  type: SHOULD_HIDE_CART,
});

export const setPaymentMethod = (payload) => ({
  type: SET_PAYMENT_METHOD,
  payload,
});

export const setCreditOrder = (payload) => ({
  type: SET_CREDIT_ORDER_ID,
  payload,
});

export const getCreditOrder = (payload) => ({
  type: GET_CREDIT_ORDER,
  payload,
});
