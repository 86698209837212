// You can delete this file if you're not using it
import wrapWithProvider from './wrap-with-provider';

window.stripeElements = () => {
  return new Promise((res, reject) => {
    return res(true);
  });
};

export const wrapRootElement = wrapWithProvider(true, true);
