import {
  FETCH_ITEM_CONFIGS_REQUEST,
  FETCH_ITEM_CONFIGS_SUCCESS,
  FETCH_ITEM_CONFIGS_FAILURE,
  SET_ASSEMBLY_OPTIONS,
  SET_MATERIAL_OPTIONS,
  SET_PLATING_OPTIONS,
  SET_POLISH_OPTIONS,
  SET_LASER_ENGRAVINGS,
} from './action';

const initialState = {
  configs: [],
  isLoading: false,
  materials: null,
  platings: null,
  polishes: null,
  assemblyOptions: null,
  laserEngravings: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEM_CONFIGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ITEM_CONFIGS_SUCCESS: {
      return {
        ...state,
        configs: action.payload,
        isLoading: false,
      };
    }
    case FETCH_ITEM_CONFIGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SET_MATERIAL_OPTIONS: {
      return {
        ...state,
        materials: action.payload,
      };
    }
    case SET_PLATING_OPTIONS: {
      return {
        ...state,
        platings: action.payload,
      };
    }
    case SET_POLISH_OPTIONS: {
      return {
        ...state,
        polishes: action.payload,
      };
    }
    case SET_ASSEMBLY_OPTIONS: {
      return {
        ...state,
        assemblyOptions: action.payload,
      };
    }
    case SET_LASER_ENGRAVINGS: {
      return {
        ...state,
        laserEngravings: action.payload,
      };
    }
    default:
      return state;
  }
};
