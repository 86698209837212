export const SET_ITEM_TYPE = 'SET_ITEM_TYPE';
export const SET_MATERIAL = 'SET_MATERIAL';
export const SET_PLATING = 'SET_PLATING';
export const SET_POLISH = 'SET_POLISH';
export const SET_ASSEMBLY_OPTION = 'SET_ASSEMBLY_OPTION';
export const SET_ASSEMBLY_SUB_ITEMS = 'SET_ASSEMBLY_SUB_ITEMS';
export const REMOVE_ASSEMBLY_SUB_ITEMS = 'REMOVE_ASSEMBLY_SUB_ITEMS';
export const REMOVE_ASSEMBLY_OPTION = 'REMOVE_ASSEMBLY_OPTION';
export const SET_LASER_ENGRAVING_OPTION = 'SET_LASER_ENGRAVING_OPTION';
export const REMOVE_LASER_ENGRAVING_OPTION = 'REMOVE_LASER_ENGRAVING_OPTION';
export const SET_LIBRARY_PRODUCT = 'SET_LIBRARY_PRODUCT';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';
export const REMOVE_SELECTION = 'REMOVE_SELECTION';
export const SET_PRICE = 'create/SET_PRICE';
export const SET_VOLUME = 'SET_VOLUME';
export const SET_SURFACE_AREA = 'SET_SURFACE_AREA';
export const SET_HEIGHT = 'SET_HEIGHT';
export const SET_WIDTH = 'SET_WIDTH';
export const SET_LENGTH = 'SET_LENGTH';
export const SET_WEIGHT = 'SET_WEIGHT';

export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const SET_MESH_LOCATION = 'SET_MESH_LOCATION';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_SCREENSHOT = 'SET_SCREENSHOT';
export const SET_OPTIONS = 'material/SET_OPTIONS';
export const SELECT_FIRST = 'material/SELECT_FIRST';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const IS_FETCHING = 'jewlery/IS_FETCHING';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const DELETE_CART_ITEM_BY_ID = 'DELETE_CART_ITEM_BY_ID';
export const CLEAR_CART = 'CLEAR_CART';
export const GENERATE_CART_ID = 'GENERATE_CART_ID';
export const SET_TAX = 'SET_TAX';
export const SET_SHIPPING_OPTION = 'SET_SHIPPING_OPTION';

export const SHOW_OFFER_CART = 'SHOW_OFFER_CART';
export const HIDE_OFFER_CART = 'HIDE_OFFER_CART';
export const ORDER_CHECKOUT_SUCCESSFUL = 'ORDER_CHECKOUT_SUCCESSFUL';
export const SET_PURCHASED_OFFERS = 'SET_PURCHASED_OFFERS';
export const SET_INITIAL_OFFERS = 'SET_INITIAL_OFFERS';

export const FETCHING_PRICE = 'create/FETCHING_PRICE';

export const MODEL_LOADING = 'MODEL_LOADING';
export const MODEL_LOADING_DONE = 'MODEL_LOADING_DONE';

export const NEW_ORDER = 'NEW_ORDER';

export const SET_FILE_DIMENSION_ERRORS = 'SET_FILE_DIMENSION_ERRORS';
export const RESET = 'create/RESET';
export const RESET_PRICE = 'price/RESET';

export const SET_ANSWER = 'SET_ANSWER';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const COMPLETE_QUES = 'COMPLETE_QUES';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const SET_TOTAL_RANKING = 'SET_TOTAL_RANKING';
export const SET_CUSTOMER_ROADMAP = 'SET_CUSTOMER_ROADMAP';
export const SET_CURRENT_MILESTONE = 'SET_CURRENT_MILESTONE';
export const UPDATE_CURRENT_MILESTONE = 'UPDATE_CURRENT_MILESTONE';
export const SET_USER_CHECKLIST = 'SET_USER_CHECKLIST';
export const UPDATE_ROADMAP = 'UPDATE_ROADMAP';
export const OPEN_MODAL_LIBRARY = 'OPEN_MODAL_LIBRARY';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_SELECTED_MARKETPLACE_MODEL = 'SET_SELECTED_MARKETPLACE_MODEL';
export const MODEL_CHOSEN = 'MODEL_CHOSEN';
export const SET_SORT_ITEM_TYPE = 'SET_SORT_ITEM_TYPE';
export const SET_MOST_POPULAR = 'SET_MOST_POPULAR';

export const UPLOAD_ASSEMBLY_FILE = 'UPLOAD_ASSEMBLY_FILE';
export const SET_VOLUME_ASSEMBLY = 'SET_VOLUME_ASSEMBLY';
export const SET_SURFACE_AREA_ASSEMBLY = 'SET_SURFACE_AREA_ASSEMBLY';

export const SET_LOAD_PROCESS = 'SET_LOAD_PROCESS';

export const OPEN_CREDIT_CARD_CHECKOUT = 'OPEN_CREDIT_CARD_CHECKOUT';

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const PRODUCT_CHOSEN = 'PRODUCT_CHOSEN';

export const SET_FONT = 'SET_FONT';
export const SET_LASER_CUSTOM_TEXT = 'SET_LASER_CUSTOM_TEXT';
export const SET_LASER_IMAGE = 'SET_LASER_IMAGE';

export const SET_PRODUCT_NAME = 'SET_PRODUCT_NAME';
export const SET_PRODUCT_DESCRIPTION = 'SET_PRODUCT_DESCRIPTION';
export const SET_PRODUCT_PRICE = 'SET_PRODUCT_PRICE';
export const SET_PRODUCT_IMAGES = 'SET_PRODUCT_IMAGES';
export const SET_GILDFORM_ITEM = 'SET_GILDFORM_ITEM';
export const SET_REDIRECTED_STRIPE = 'SET_REDIRECTED_STRIPE';
export const SET_PRICE_ASSEMBLY = 'SET_PRICE_ASSEMBLY';
export const SET_ASSEMBLY_STEP = 'SET_ASSEMBLY_STEP';
export const SET_PRODUCT_SHOPIFY = 'SET_PRODUCT_SHOPIFY'
export const OPEN_DESIGN_WITH_US_MODAL = 'OPEN_DESIGN_WITH_US_MODAL'
export const REMOVE_FILE_DIMENSION_ERRORS = 'REMOVE_FILE_DIMENSION_ERRORS';
