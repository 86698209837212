import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import steps from './steps/reducer';
import create from './create';
import cart from './shoppingCart/reducer';
import checkout from './checkout/reducer';
import login from './login/reducer';
import itemConfigs from './itemConfigs/reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'login', 'checkout', 'itemConfigs'],
};

const reducer = combineReducers({
  steps,
  create,
  itemConfigs,
  cart,
  checkout,
  login,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const createStore = (isBrowser) => {
  let store;
  if (isBrowser) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
    store = reduxCreateStore(
      persistedReducer,
      composeEnhancers(applyMiddleware(thunk))
    );
  } else {
    store = reduxCreateStore(reducer);
  }
  const persistor = persistStore(store);

  return { store, persistor };
};

export default createStore;
