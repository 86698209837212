import {
  SET_PRODUCT_DESCRIPTION,
  SET_PRODUCT_IMAGES,
  SET_PRODUCT_NAME,
  SET_PRODUCT_PRICE,
} from '../../actionTypes';

const initialState = {
  name: null,
  description: null,
  price: null,
  images: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case SET_PRODUCT_DESCRIPTION: {
      return {
        ...state,
        description: action.payload,
      };
    }
    case SET_PRODUCT_PRICE: {
      return {
        ...state,
        price: action.payload,
      };
    }
    case SET_PRODUCT_IMAGES: {
      return {
        ...state,
        images: action.payload,
      };
    }
    default:
      return state;
  }
};
