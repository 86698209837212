import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import createStore from './src/state/store';

export default (shouldGate, isBrowser) => ({ element }) => {
  const { store, persistor } = createStore(isBrowser);

  let child = element;

  if (shouldGate) {
    child = (
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    );
  }
  return <Provider store={store}>{child}</Provider>;
};
