import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  CLEAR_CART,
  DELETE_CART_ITEM_BY_ID,
  GENERATE_CART_ID,
  SHOW_OFFER_CART,
  HIDE_OFFER_CART,
  ORDER_CHECKOUT_SUCCESSFUL,
  SET_PURCHASED_OFFERS,
  SET_INITIAL_OFFERS,
  // ADD_OFFER_PRODUCT_TO_CART,
  // CLEAR_OFFER_PRODUCT_CART
} from '../actionTypes';

const initialState = {
  id: null,
  items: [],
  // offerProductItems: [],
  purchasedOffers: [],
  initialOffers: [],
  addToCartLoading: false,
  showOfferCart: false,
  orderCheckoutSuccessful: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_REQUEST: {
      return {
        ...state,
        addToCartLoading: true,
      };
    }
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload],
        addToCartLoading: false,
      };
    }
    case ADD_TO_CART_FAILURE: {
      return {
        ...state,
        addToCartLoading: false,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        id: null,
        items: [],
        addToCartLoading: false,
      };
    }
    case DELETE_CART_ITEM_BY_ID: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
      };
    }
    case GENERATE_CART_ID: {
      return {
        ...state,
        id: action.id,
      };
    }
    case SHOW_OFFER_CART: {
      return {
        ...state,
        showOfferCart: true,
      };
    }
    case HIDE_OFFER_CART: {
      return {
        ...state,
        showOfferCart: false,
      };
    }
    case ORDER_CHECKOUT_SUCCESSFUL: {
      return {
        ...state,
        orderCheckoutSuccessful: action.payload,
      };
    }
    case SET_PURCHASED_OFFERS: {
      return {
        ...state,
        purchasedOffers: action.payload,
      };
    }
    case SET_INITIAL_OFFERS: {
      return {
        ...state,
        initialOffers: action.payload,
      };
    }
    default:
      return state;
  }
};
