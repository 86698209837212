import {
  SET_ANSWER,
  SET_QUESTIONS,
  COMPLETE_QUES,
  UPDATE_ANSWER,
  SET_TOTAL_RANKING,
  SET_CUSTOMER_ROADMAP,
  SET_CURRENT_MILESTONE,
  UPDATE_CURRENT_MILESTONE,
  SET_USER_CHECKLIST,
  UPDATE_ROADMAP,
} from '../../actionTypes';

const initialState = {
  answers: [],
  questions: [],
  total_ranking: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANSWER: {
      return {
        ...state,
        answers: [...state.answers, action.payload].sort(
          (a, b) => a.step - b.step
        ),
      };
    }
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: action.payload,
      };
    }
    case COMPLETE_QUES: {
      return {
        ...state,
        questions: state.questions.map((ques) =>
          ques.id === action.payload.question.id
            ? { ...ques, completed: action.payload.completed }
            : ques
        ),
      };
    }
    case SET_TOTAL_RANKING: {
      return {
        ...state,
        total_ranking: action.payload,
      };
    }
    case UPDATE_ANSWER: {
      return {
        ...state,
        answers: state.answers.filter(
          (ans) => ans.question !== action.payload.question
        ),
      };
    }
    case SET_CUSTOMER_ROADMAP: {
      return {
        ...state,
        roadmap: action.payload,
      };
    }
    case UPDATE_ROADMAP: {
      return {
        ...state,
        roadmap: { ...state.roadmap, milestones: action.payload },
      };
    }
    case SET_CURRENT_MILESTONE: {
      return {
        ...state,
        currentMilestone: action.payload,
      };
    }
    case UPDATE_CURRENT_MILESTONE: {
      return {
        ...state,
        currentMilestone: {
          ...state.currentMilestone,
          questions: action.payload,
        },
      };
    }
    case SET_USER_CHECKLIST: {
      return {
        ...state,
        checklist: {
          ...state.checklist,
          checklist: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
