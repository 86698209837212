import {
  UPDATE_SHIPPING_INFO,
  UPDATE_BILLING_INFO,
  CHARGING,
  CHARGED,
  SHOULD_HIDE_CART,
  SHOULD_SHOW_CART,
  SET_PAYMENT_METHOD,
  SET_CREDIT_ORDER_ID,
  GET_CREDIT_ORDER,
} from './actions';

const initialState = {
  shipping: {},
  billing: {},
  isCharging: false,
  showCart: false,
  paymentMethod: 'Default',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BILLING_INFO: {
      return {
        ...state,
        billing: {
          ...state.billing,
          [action.field]: action.value,
        },
      };
    }
    case UPDATE_SHIPPING_INFO: {
      return {
        ...state,
        shipping: {
          ...state.shipping,
          [action.field]: action.value,
        },
      };
    }
    case CHARGING:
      return {
        ...state,
        isCharging: true,
      };
    case CHARGED: {
      return {
        ...state,
        isCharging: false,
      };
    }
    case SHOULD_HIDE_CART: {
      return {
        ...state,
        showCart: false,
      };
    }
    case SHOULD_SHOW_CART: {
      return {
        ...state,
        showCart: true,
      };
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.payload,
      };
    }
    case SET_CREDIT_ORDER_ID: {
      return {
        ...state,
        creditOrder: action.payload,
      };
    }
    case GET_CREDIT_ORDER: {
      return {
        ...state,
        credit_key_order: action.payload,
      };
    }
    default:
      return state;
  }
};
