import { SET_GILDFORM_ITEM, SET_REDIRECTED_STRIPE } from '../actionTypes';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const CHECKED = 'CHECKED_LOGIN';
export const SET_SHOPIFY_URL = 'SET_SHOPIFY_URL';
export const SET_SHOPIFY_ACCESS_TOKEN = 'SET_SHOPIFY_ACCESS_TOKEN';
export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAIL = 'EDIT_FAIL';
export const FINISH_EDIT = 'FINISH_EDIT';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS';
export const SET_IS_FIRST_TIME_LOGIN = 'SET_IS_FIRST_TIME_LOGIN';

export const Login = (fullAuth) => ({
  type: LOGIN,
  fullAuth,
});

export const logout = () => ({
  type: LOGOUT,
});

export const getUser = (user) => ({
  type: GET_USER,
  user,
});

export const lastChecked = () => ({
  type: CHECKED,
});

export const editRequest = () => ({
  type: EDIT_REQUEST,
});

export const finishEdit = () => ({
  type: FINISH_EDIT,
});

export const setShopifyUrl = (payload) => ({
  type: SET_SHOPIFY_URL,
  payload,
});

export const setShopifyAccessToken = (payload) => ({
  type: SET_SHOPIFY_ACCESS_TOKEN,
  payload,
});

export const updateChecklist = (payload) => ({
  type: UPDATE_CHECKLIST,
  payload,
});

export const setUserProjects = (payload) => ({
  type: SET_USER_PROJECTS,
  payload,
});

export const setFirstTimeLogin = (payload) => ({
  type: SET_IS_FIRST_TIME_LOGIN,
  payload,
});

export const setUserGildformItem = (payload) => ({
  type: SET_GILDFORM_ITEM,
  payload,
});

export const setRedirectedFromStripe = (payload) => ({
  type: SET_REDIRECTED_STRIPE,
  payload,
});
