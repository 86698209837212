import { combineReducers } from 'redux';

import jewelry from './jewelry/reducer';
import price from './price/reducer';
import answer from './answer/reducer';
import gildformStore from './gildformStore/reducer';

export default combineReducers({
  jewelry,
  price,
  answer,
  gildformStore,
});
